import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import React from 'react'
import Seo from 'utils/seo/Seo'
import { useFinancedProjectsData } from './hooks/useFinancedProjectsData'
import Layout from 'containers/layout/Layout'
import { colors, pageUrls } from 'shared/constants'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { theme } from 'theme/theme'
import Breadcrumbs from 'components/shared-components/breadcrumbs/Breadcrumbs'

const linkResolver = require('../../../../linkResolver.js')

const Wrapper = styled.div(() => {
  const { mobile, tablet } = useMediaQuery()
  
  return css`
    padding: 28px 84px;
    max-width: ${theme.sizes.container};
    margin: 0 auto;

    ${tablet && 'padding: 40px 25px'}
    ${mobile && 'padding: 25px 15px'}
  `
});

const BreadcrumbsWrapper = styled.div`
  overflow-x: hidden;
  padding: 40px 0;
`

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.large}) {
    flex-direction: column;
  }
`

const LeftSide = styled.div`
  display: flex;
  align-items: left;
  width: 57%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.large}) {
    width: 100%;
    order: 1;
    margin-top: 0;
  }
`

const Category = styled.p`
  opacity: 50%;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 52px;
  letter-spacing: 2px;
  margin-bottom: 16px;

  @media (max-width: ${theme.breakpoints.small}) {
    margin-bottom: 0;
  }
`

const Title = styled.h1`
  color: ${colors.darkBlue};
  font-size: 50px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: 16px;

  @media (max-width: ${theme.breakpoints.large}) {
    font-size: 42px;
    line-height: 51px;           
  }
  @media (max-width: ${theme.breakpoints.tablet}) {
    font-size: 32px;
    line-height: 39px;
  }
`

const RightSide = styled.div`
  width: 43%;
  margin-top: 30px;
  display: flex;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.large}) {
    width: 100%;
    order: 2;
    display: flex;
    justify-content: flex-end;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 300px;
`

const HashtagsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
`

const Hashtag = styled.p`
  font-size: 20px;
  line-height: 24px;
  margin-right: 24px;
`

const ContentWrapper = styled.div`
  margin-top: 80px;
`

const Content = styled.div`
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
  }

  h2 {
    font-size: 28px;
    line-height: 42px;
  }
`

const FinancedProjects = (): JSX.Element => {
  const financed = useFinancedProjectsData()

    return (
      <>
        <Seo
          title={financed?.seo_title?.text}
          description={financed?.seo_description?.text}
          canonicalUrl={financed?.seo_canonical_url?.url}
        />
        <Layout backgroundColor={colors.background}>
          <Wrapper>
            <BreadcrumbsWrapper>
              <Breadcrumbs first='dataplace.ai' firstLink='' second={RichText.asText(financed.seo_title?.richText)} secondLink={`${pageUrls.financedProjects}`} />
            </BreadcrumbsWrapper>
            <TopSection>
                <LeftSide>
                  <Category>{RichText.asText(financed.seo_title?.richText)}</Category>
                  <Title>{RichText.asText(financed.header_title?.richText)}</Title>
                </LeftSide>
                <RightSide>
                  <StyledGatsbyImage image={financed?.header_image?.gatsbyImageData} alt={financed?.header_image?.alt ?? ''} />
                </RightSide>
            </TopSection>
            <ContentWrapper>
              <Content>{RichText.render(financed.financed_page_content?.richText)}</Content>
              {financed.financed_hashtags?.map((item, index) =>
                <HashtagsSection key={index}>
                  <Hashtag>{RichText.asText(item?.hashtag?.richText)}</Hashtag>
                </HashtagsSection>
              )}
            </ContentWrapper>
          </Wrapper>
        </Layout>
      </>
    )
}

export default withPrismicPreview(FinancedProjects, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
])
