import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DownloadReport,
  GetReportDetails,
  Pagination,
  Report,
  SearchFilter,
} from "features/reports/reportsModel";
import { Maybe } from "types/declarations";
import { defaultReportFilters } from "features/reports/reportsExampleData";
import { RootState } from "utils/store/store";
import ReportsService from "features/reports/reportsService";

export interface ReportsState {
  searchQuery: string;
  searchResults: Array<Report>;
  allReports: Array<Report>;
  homeReports: Array<Report>;
  searchFilters: Array<SearchFilter>;
  pagination: Pagination;
  reportDetails: Maybe<Report>;
}

const initialState = {
  searchQuery: "",
  searchResults: [],
  allReports: [],
  homeReports: [],
  searchFilters: defaultReportFilters,
  pagination: { page: 1, pageSize: 12, numberOfItems: 0 },
  reportDetails: null,
} as ReportsState;

export const toggleFilter = createAsyncThunk(
  "reports/toggleFilter",
  async (variables: SearchFilter, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const query = state.reports.searchQuery;
    thunkAPI.dispatch(toggleFilterState(variables));
    if (query) {
      thunkAPI.dispatch(getSearchResults(query));
    } else {
      thunkAPI.dispatch(getHomeReports());
    }
  }
);

export const getSearchResults = createAsyncThunk(
  "reports/getSearchResults",
  async (query: string, thunkAPI) => {
    thunkAPI.dispatch(setSearchQuery(query));
    const state = thunkAPI?.getState() as RootState;
    const filters = state?.reports?.searchFilters;
    const pagination = state?.reports?.pagination;
    const reports = state?.reports?.allReports;

    return ReportsService.getSearchResults({
      query,
      filters,
      pagination,
      allReports: reports,
    });
  }
);

export const getHomeReports = createAsyncThunk(
  "reports/getHomeReports",
  async (_, thunkAPI) => {
    const state = thunkAPI?.getState() as RootState;
    const filters = state?.reports?.searchFilters;
    const pagination = state?.reports?.pagination;
    const reports = state?.reports?.allReports;

    const results = ReportsService?.getHomeReports({
      filters,
      pagination,
      allReports: reports,
    });
    thunkAPI?.dispatch(
      setPagination({
        ...pagination,
        numberOfItems: results?.length,
      })
    );
    return results;
  }
);

export const paginateReports = createAsyncThunk(
  "reports/paginateReports",
  async (pagination: Pagination, thunkAPI) => {
    const state = thunkAPI?.getState() as RootState;
    const filters = state?.reports?.searchFilters;
    const query = state?.reports?.searchQuery;
    const reports = state?.reports?.allReports;

    if (query) {
      const results = ReportsService?.getSearchResults({
        query,
        filters,
        pagination,
        allReports: reports,
      });
      thunkAPI.dispatch(
        setPagination({ ...pagination, numberOfItems: results?.length })
      );
      thunkAPI.dispatch(setSearchResults(results));
      return results;
    } else {
      const results = ReportsService?.getHomeReports({
        filters,
        pagination,
        allReports: reports,
      });
      thunkAPI.dispatch(
        setPagination({
          ...pagination,
          numberOfItems: results?.length,
        })
      );
      thunkAPI.dispatch(setHomeReports(results));
      return results;
    }
  }
);

export const getReportDetails = createAsyncThunk(
  "reports/getReportDetails",
  async (props: GetReportDetails, thunkAPI) => {
    const state = thunkAPI?.getState() as RootState;
    const reports = state?.reports?.allReports;
    return ReportsService?.getReportDetails({
      ...props,
      allReports: reports as Array<Report>,
    });
  }
);

export const downloadReport = createAsyncThunk(
  "reports/downloadReport",
  async (variables: DownloadReport) => {
    return await ReportsService?.downloadReport(
      variables,
      variables.lang,
      variables.setInfoModalText,
      variables?.setModalDisplay,
      variables.formId,
      variables?.openReportInNewTab,
      variables?.modalInfoText
    );
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    toggleFilterState(state, action: PayloadAction<SearchFilter>) {
      const allIndex = state.searchFilters.findIndex((filter: SearchFilter) =>
        filter?.value?.toLowerCase().includes("wszystko")
      );
      const foundIndex = state.searchFilters.findIndex(
        (filter: SearchFilter) => filter?.id === action.payload.id
      );

      // Return if invalid filter clicked
      if (foundIndex === -1) {
        return;
      }

      // When clicked all filter
      if (allIndex === foundIndex) {
        state.searchFilters = state.searchFilters.map(
          (filter: SearchFilter) => {
            return { ...filter, selected: false };
          }
        );
        state.searchFilters[allIndex].selected = true;
      } else {
        state.searchFilters[foundIndex].selected = !state.searchFilters[
          foundIndex
        ].selected;

        if (allIndex !== -1) {
          state.searchFilters[allIndex].selected = false;
        }
      }
    },
    setAllReports(state, action: PayloadAction<Array<Report>>) {
      state.allReports = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    setFilters(state, action: PayloadAction<Array<SearchFilter>>) {
      state.searchFilters = action.payload;
    },
    setHomeReports(state, action: PayloadAction<Array<Report>>) {
      state.homeReports = action.payload;
    },
    setSearchResults(state, action: PayloadAction<Array<Report>>) {
      state.searchResults = action.payload;
    },
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    clearSearch(state) {
      state.searchQuery = "";
      state.homeReports = [];
      state.searchResults = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getHomeReports.fulfilled, (state, action) => {
      state.homeReports = action.payload;
    });
    builder.addCase(getSearchResults.fulfilled, (state, action) => {
      state.searchResults = action.payload;
    });
    builder.addCase(getReportDetails.fulfilled, (state, action) => {
      state.reportDetails = action.payload;
    });
    builder.addCase(paginateReports.fulfilled, () => {
      if (typeof window !== "undefined") {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    });
  },
});

export const {
  toggleFilterState,
  setSearchQuery,
  setFilters,
  setPagination,
  setHomeReports,
  setAllReports,
  setSearchResults,
  clearSearch,
} = reportsSlice.actions;
export default reportsSlice.reducer;
