import { setAllBlogPosts, setHomeBannersDTO } from "features/blog/blogSlice";
import { setAllSuccessStories } from "features/success-stories/successStoriesSlice";
import React from "react";
import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PrismicBlog,
  PrismicBlogEdge,
  PrismicBlogPost,
  PrismicBlogPostEdge,
  PrismicRecruitmentPost,
  PrismicRecruitmentPostEdge,
  PrismicReport,
  PrismicReportEdge,
  PrismicSuccessStory,
  PrismicSuccessStoryEdge,
} from "types/declarations";
import { RichText } from "prismic-reactjs";
import { BlogBannerDTO, BlogPostDetails } from "features/blog/blogModel";
import { RootState } from "utils/store/store";
import { graphql, useStaticQuery } from "gatsby";
import { setAllReports } from "features/reports/reportsSlice";
import { Report } from "features/reports/reportsModel";
import { useLocation } from "@reach/router";
import { setLastPage } from "features/not-found/notFoundSlice";
import { detectLanguage } from "utils/i18n/i18nSlice";
import { RecruitmentPost } from "features/career/careerModel";
import { setAllRecruitmentPosts } from "features/career/careerSlice";
import { SuccessStory } from "features/success-stories/successStoriesModel";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import useScrollToRef from "utils/hooks/useScrollToRef";

export interface RootContainerProps {
  children: React.ReactNode;
}

const RootContainer: FC<RootContainerProps> = ({
  children,
}: RootContainerProps) => {
  const dispatch = useDispatch();
  const scrollRef = useScrollToRef();
  const { language } = useSelector((state: RootState) => state.i18n);
  const { allBlogPosts } = useSelector((state: RootState) => state.blog);
  const { allRecruitmentPosts } = useSelector((state: RootState) => state.recruitment);
  const { allReports } = useSelector((state: RootState) => state.reports);
  const { allSuccessStories } = useSelector(
    (state: RootState) => state.successStories
  );
  
  const requestS = useStaticQuery(graphql`
    {
      allPrismicReport {
        edges {
          node {
            id
            lang
            tags
            _previewable
            data {
              case_studies {
                case_study_tag {
                  raw
                }
                case_study_button {
                  raw
                }
                case_study_description {
                  raw
                }
                case_study_header {
                  raw
                }
                case_study_image {
                  url
                }
                case_study_link {
                  raw
                }
              }
              order {
                raw
              }
              seo_title {
                raw
              }
              seo_description {
                raw
              }
              seo_canonical_url {
                url
              }
              hubspot_form_id {
                raw
              }
              label {
                raw
              }
              case_studies_text {
                raw
              }
              title {
                raw
              }
              description {
                raw
              }
              link {
                raw
              }
              points {
                raw
              }
              release_date
              report_image {
                gatsbyImageData
                url
                alt
              }
              post_miniature_img {
                gatsbyImageData
                url
                alt
              }
              report_pdf {
                url
              }
              is_featured
              open_report_in_new_tab
              modal_info_text {
                raw
              }
              form_guid {
                raw
              }
              portal_id {
                raw
              }
              form_departament_options {
                raw
              }
              form_industry_options {
                raw
              }
              form_name {
                raw
              }
              form_button {
                raw
              }
              form_departament {
                raw
              }
              form_industry {
                raw
              }
              form_mail {
                raw
              }
              form_phone {
                raw
              }
              body {
                ... on PrismicReportDataBodyReportBanner {
                  id
                  primary {
                    image {
                      gatsbyImageData
                      alt
                      url
                    }
                    title1 {
                      raw
                    }
                  }
                  slice_type
                  items {
                    column_description {
                      raw
                    }
                    column_title {
                      raw
                    }
                  }
                }
                ... on PrismicReportDataBodyReportSummary {
                  id
                  slice_type
                  primary {
                    content {
                      raw
                    }
                    title1 {
                      raw
                    }
                  }
                }
                ... on PrismicReportDataBodyReportFacts {
                  id
                  slice_type
                  primary {
                    content {
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    title1 {
                      raw
                    }
                  }
                }
              }
            }
          }
        }
      }

      allPrismicBlog {
        edges {
          node {
            lang
            tags
            _previewable
            data {
              body {
                ... on PrismicBlogDataBodyFormBanners {
                  slice_type
                  items {
                    image {
                      url
                      alt
                    }
                    button {
                      raw
                    }
                    header {
                      raw
                    }
                    industry {
                      raw
                    }
                    industry_options {
                      raw
                    }
                    mail {
                      raw
                    }
                    position {
                      raw
                    }
                  }
                }

                ... on PrismicBlogDataBodyBanners {
                  slice_type
                  items {
                    button {
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    link {
                      raw
                    }
                    title {
                      raw
                    }
                    position {
                      raw
                    }
                  }
                }
              }
            }
          }
        }
      }

      allPrismicBlogPost {
        edges {
          node {
            lang
            tags
            _previewable
            data {
              seo_title {
                raw
              }
              seo_description {
                raw
              }
              seo_canonical_url {
                url
              }
              link {
                text
              }
              order {
                raw
              }
              release_date
              title {
                raw
              }
              description {
                raw
              }
              post_category {
                raw
              }
              post_labels {
                raw
              }
              blog_image {
                gatsbyImageData
                url
                alt
              }
              is_featured
              table_of_contents {
                raw
              }
              body {
                ... on PrismicBlogPostDataBodyLine {
                  slice_label
                  slice_type
                  primary {
                    line {
                      raw
                    }
                  }
                }
                ... on PrismicBlogPostDataBodySimilarPosts {
                  items {
                    similar_description {
                      raw
                    }
                    similar_link {
                      raw
                    }
                    similar_title {
                      raw
                    }
                    tags {
                      raw
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicBlogPostDataBodyProductBanner {
                  slice_type
                  slice_label
                  items {
                    color
                    text {
                      raw
                    }
                    first_button_link {
                      raw
                    }
                    first_button_text {
                      raw
                    }
                    sub_text {
                      raw
                    }
                    banner_title {
                      raw
                    }
                  }
                }
                ... on PrismicBlogPostDataBodyReportSliceInsidePost {
                  slice_type
                  slice_label
                  items {
                    report_title {
                      raw
                    }
                    points {
                      raw
                    }
                    button_text {
                      raw
                    }
                    button_link {
                      raw
                    }
                    report_image {
                      url
                    }
                  }
                }
                ... on PrismicBlogPostDataBodyImage {
                  slice_label
                  slice_type
                  primary {
                    image {
                      gatsbyImageData
                      url
                      alt
                    }
                  }
                }
                ... on PrismicBlogPostDataBodyText {
                  slice_label
                  slice_type
                  primary {
                    text {
                      raw
                    }
                  }
                }
                ... on PrismicBlogPostDataBodyTip {
                  slice_type
                  primary {
                    tip_content {
                      raw
                    }
                    tip_icon {
                      alt
                      url
                    }
                    tip_title {
                      raw
                    }
                  }
                }
              }
            }
          }
        }
      }

      allPrismicSuccessStory {
        edges {
          node {
            lang
            tags
            _previewable
            data {
              body {
                ... on PrismicSuccessStoryDataBodyCredentialsBanner {
                  slice_type
                  primary {
                    banner_link {
                      raw
                    }
                    banner_client_logo {
                      alt
                      url
                    }
                    banner_img {
                      gatsbyImageData
                      alt
                      url
                    }
                    banner_person {
                      raw
                    }
                    banner_title {
                      raw
                    }
                  }
                }
                ... on PrismicSuccessStoryDataBodySimmilarSuccessStory {
                  id
                  slice_type
                  items {
                    similar_client {
                      alt
                      url
                    }
                    similar_description {
                      raw
                    }
                    similar_industry {
                      raw
                    }
                    similar_products {
                      raw
                    }
                    similar_title {
                      raw
                    }
                    similar_link {
                      raw
                    }
                  }
                }
                ... on PrismicSuccessStoryDataBodyEffects {
                  slice_type
                  primary {
                    effects_text {
                      raw
                    }
                  }
                }
                ... on PrismicSuccessStoryDataBodyText {
                  slice_type
                  primary {
                    text {
                      raw
                    }
                  }
                }
                ... on PrismicSuccessStoryDataBodyImage {
                  slice_type
                  primary {
                    image {
                      gatsbyImageData
                      url
                      alt
                    }
                  }
                }
                ... on PrismicSuccessStoryDataBodySidebarBox {
                  slice_type
                  primary {
                    box_button_label {
                      raw
                    }
                    box_description {
                      raw
                    }
                    box_product {
                      raw
                    }
                    box_title {
                      raw
                    }
                    box_button_link {
                      raw
                    }
                  }
                }
              }
              client_industry {
                raw
              }
              client_name {
                raw
              }
              client_logo {
                gatsbyImageData
                alt
                url
              }
              description {
                raw
              }
              link {
                raw
              }
              labels {
                text
              }
              title {
                raw
              }
              seo_title {
                raw
              }
              seo_description {
                raw
              }
              seo_canonical_url {
                url
              }
              release_date
            }
          }
        }
      }

      allPrismicRecruitmentPost {
        edges {
          node {
            lang
            tags
            _previewable
            data {
              post_link {
                text
              }
              animals_label {
                raw
              }
              body {
                ... on PrismicRecruitmentPostDataBodyText {
                  id
                  primary {
                    text {
                      raw
                    }
                  }
                  slice_type
                  slice_label
                }
                ... on PrismicRecruitmentPostDataBodyHighlight {
                  id
                  primary {
                    text {
                      raw
                    }
                  }
                  slice_label
                  slice_type
                }
              }
              post_contract {
                raw
              }
              post_description {
                raw
              }
              post_icons {
                icon {
                  alt
                  url
                }
                label {
                  raw
                }
              }
              post_img {
                gatsbyImageData
                alt
                url
              }
              post_location {
                raw
              }
              post_order
              post_salary {
                raw
              }
              post_title {
                raw
              }
              post_worktime {
                raw
              }
            }
            id
          }
        }
      }
    }
  `);
  const {data: request} = useMergePrismicPreviewData(requestS)

  const location = useLocation();

  const sortPosts = (a: RecruitmentPost, b: RecruitmentPost) => {
    if ( a?.order === null) return 1;
    else if ( b?.order === null) return -1;
    else return a?.order - b?.order;
  };

  useEffect(() => {
    dispatch(setLastPage(location.pathname));
  }, [location.pathname]);

  const posts = useMemo(() => {
    return request?.allPrismicBlogPost?.edges
      .map((edge: PrismicBlogPostEdge) => edge?.node)
      .filter((node: PrismicBlogPost) => {
        if (language === 'pl') {
          return node.lang === language
        } else {
          return node.lang === 'en-pl'
        }
      })
      .map((data: PrismicBlogPost) => {
        const post = data?.data;
        return {
          content: post?.body,
          link: post?.link?.text,
          image: post?.blog_image,
          title: RichText.asText(post?.title?.raw || []),
          description: RichText.asText(post?.description?.raw || []),
          isFeatured: post?.is_featured,
          post_category: post?.post_category,
          post_labels: post?.post_labels,
          table_of_contents: post?.table_of_contents,
          seo_title: RichText.asText(post?.seo_title?.raw || []),
          seo_description: RichText.asText(post?.seo_title?.raw || []),
          seo_canonical_url: post?.seo_canonical_url?.url,
          order: RichText.asText(post?.order?.raw || []),
          tags: data?.tags?.map(t => {
            return { value: t };
          }),
        } as BlogPostDetails;
      }) as Array<BlogPostDetails>;
  }, [request, language]) as Array<BlogPostDetails>;

  const blogBanners = useMemo(() => {
    return request.allPrismicBlog.edges
      .map((edge: PrismicBlogEdge) => edge.node)
      .filter((node: PrismicBlog) => {
        if (language === "pl") {
          return node.lang === language;
        } else {
          return node.lang === "en-pl";
        }
      })
      .map((data: PrismicBlog) => {
        return {
          body: data.data?.body,
        } as BlogBannerDTO;
      });
  }, [request, language]) as Array<BlogBannerDTO>;

  const reports = useMemo(() => {
    return request?.allPrismicReport?.edges
      .map((edge: PrismicReportEdge) => edge?.node)
      .filter((node: PrismicBlog) => {
        if (language === "pl") {
          return node?.lang === language;
        } else {
          return node?.lang === "pl";
        }
      })
      .map((data: PrismicReport) => {
        const report = data?.data;
        return ({
          label: RichText.asText(report?.label?.raw || []),
          id: data?.id,
          link: RichText.asText(report?.link?.raw || []),
          hubspotFormId: RichText.asText(report?.hubspot_form_id?.raw || []),
          image: report?.report_image,
          minImage: report?.post_miniature_img,
          title: RichText.asText(report?.title?.raw || []),
          description: RichText.asText(report?.description?.raw || []),
          points: report?.points,
          isFeatured: report?.is_featured,
          open_report_in_new_tab: report?.open_report_in_new_tab,
          modal_info_text: report?.modal_info_text,
          pdf: report?.report_pdf?.url,
          formGuid: RichText.asText(report?.form_guid?.raw || []),
          portalId: RichText.asText(report?.portal_id?.raw || []),
          form: report,
          case_studies: report?.case_studies,
          case_studies_text: report?.case_studies_text,
          content: report?.body,
          date: report?.release_date,
          order: RichText.asText(report?.order?.raw || []),
          seo_title: RichText.asText(report?.seo_title?.raw || []),
          seo_description: RichText.asText(report?.seo_title?.raw || []),
          seo_canonical_url: report?.seo_canonical_url?.url,
          tags: data?.tags?.map(t =>
          {
            return { value: t };
          }),
        } as unknown) as Report;
      }) as Array<Report>;
  }, [request, language]) as Array<Report>;

  const recruitmentPosts = useMemo(() => {
    return request.allPrismicRecruitmentPost.edges
      .map((edge: PrismicRecruitmentPostEdge) => edge.node)
      .filter((node: PrismicRecruitmentPost) =>{
        if(language === 'pl') {
          return node.lang === language
        } else {
          return node.lang === 'pl'
        }
      })
      .map((data: PrismicRecruitmentPost) => {
        const post = data.data;
        return {
          id: data.id,
          content: post?.body,
          link: post?.post_link?.text,
          order: post?.post_order,
          title: RichText.asText(post?.post_title?.raw || []),
          salary: RichText.asText(post?.post_salary?.raw || []),
          worktime: RichText.asText(post?.post_worktime?.raw || []),
          location: RichText.asText(post?.post_location?.raw || []),
          contract: RichText.asText(post?.post_contract?.raw || []),
          image: post?.post_img,
          description: RichText.asText(post?.post_description?.raw || []),
          post_icons: post?.post_icons,
          animals_label: RichText.asText(post?.animals_label?.raw || []),
          tags: data.tags.map(t => {
            return { value: t };
          }),
        } as unknown as RecruitmentPost;
      }).sort(sortPosts) as Array<RecruitmentPost>;
  }, [request, language]) as Array<RecruitmentPost>;

  const successStories = useMemo(() => {
    return request?.allPrismicSuccessStory?.edges
      .map((edge: PrismicSuccessStoryEdge) => edge?.node)
      .filter((node: PrismicSuccessStory) => {
        if (language === "pl") {
          return node?.lang === language;
        } else {
          return node?.lang === "en-pl";
        }
      })
      .map((data: PrismicSuccessStory) => {
        const successStory = data?.data;
        return {
          id: data?.id,
          link: RichText.asText(successStory?.link?.raw || []),
          title: RichText.asText(successStory?.title?.raw || []),
          description: RichText.asText(successStory?.description?.raw || []),
          client_industry: RichText.asText(successStory?.client_industry?.raw || []),
          client_name: RichText.asText(successStory?.client_name?.raw || []),
          client_logo: successStory?.client_logo,
          labels: successStory?.labels,
          content: successStory?.body,
          seo_title: RichText.asText(successStory?.seo_title?.raw || []),
          seo_description: RichText.asText(successStory?.seo_title?.raw || []),
          seo_canonical_url: successStory?.seo_canonical_url?.url,
          date: successStory?.release_date,
          tags: data?.tags?.map(t => {
            return { value: t };
          }),
        } as SuccessStory;
      }) as Array<SuccessStory>;
  }, [request, language]) as Array<SuccessStory>;

  useEffect(() => {
    dispatch(setAllBlogPosts(posts));
    dispatch(setAllReports(reports));
    dispatch(setHomeBannersDTO(blogBanners));
    dispatch(setAllRecruitmentPosts(recruitmentPosts));
    dispatch(setAllSuccessStories(successStories));
  }, [dispatch, posts, reports, blogBanners, recruitmentPosts, successStories]);
  
  useEffect(()=>{
    if(!language.length){
    dispatch(detectLanguage())
    }
  }, [])

  useEffect(() => {
    if (!language.length) {
      dispatch(detectLanguage());
    }
  }, []);

  if (
    allBlogPosts.length !== 0 &&
    allReports.length !== 0 &&
    blogBanners.length !== 0 &&
    allRecruitmentPosts.length !== 0 &&
    allSuccessStories.length !== 0
  ) {
    return (
      <>
        <div ref={scrollRef} />
        {children}
      </>
    )
  } else {
    return null;
  }
};
export default RootContainer;
