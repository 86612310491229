/* eslint-disable no-useless-escape */
import React, { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { downloadReport } from "features/reports/reportsSlice";
import { Report } from "features/reports/reportsModel";
import { Maybe } from "types/declarations";
import Select from "ui/select/Select";
import { RichText } from "prismic-reactjs";
import { RootState } from "utils/store/store";
import { useSelector } from 'react-redux'
import { Link } from 'gatsby'
import Close from '../../../../assets/close.svg'
import MessageIcon from '../../../../assets/message.svg'
import ErrorIcon from '../../../../assets/error.svg'
import { useLocale } from "locale/useLocale";
import Arrow from "assets/arrowIcon.svg";
import { emailRegex } from "utils/constants";

export interface ReportFormProps {
  report: Maybe<Report>;
  arrow?: boolean;
}

const ReportForm: FC<ReportFormProps> = ({ report, arrow }: ReportFormProps) => {
  const dispatch = useDispatch();
  const locale = useLocale("Report");

  const [mail, setMail] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");
  const [newsletterChecked, setNewsletterChecked] = useState(true);
  const [newsletter, setNewsletter] = useState<string>('Yes');
  const { language } = useSelector((state: RootState) => state.i18n);
  const [infoModalDisplay, setModalDisplay] = useState(false)
  const [infoModalText, setInfoModalText] = useState('')
  const [mobileForm, setMobileForm] = useState(false)

  const industryOptions = RichText.asText(
    report?.form?.form_industry_options?.raw || []
  )?.split(";");

  const formDisabled = useMemo(() => {
    return !mail || !industry || !emailRegex.test(mail);
  }, [mail, industry]);

  const onMailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
  }, []);

  const onIndustryChange = useCallback((value: string) => {
    setIndustry(value);
  }, []);

  const onNewsletterChange = useCallback((value: boolean) => {
    if (value === true) {
      setNewsletter('Yes')
      setNewsletterChecked(true)
    } else {
      setNewsletter('No')
      setNewsletterChecked(false)
    }
  }, []);

  const onDownloadReport = useCallback(() => {
    dispatch(
      downloadReport({
        industry,
        mail,
        reportPdf: report?.pdf || "",
        newsletter: newsletterChecked,
        lang: language,
        setModalDisplay,
        setInfoModalText,
        formId: report?.hubspotFormId || "",
        openReportInNewTab: report?.open_report_in_new_tab || false,
        modalInfoText: RichText.asText(report?.modal_info_text?.raw) || ''
      })
    );
  }, [
    industry,
    mail,
    report?.portalId,
    report?.formGuid,
  ]);

  return (
    <>
      {infoModalDisplay && <div className='info-modal-newsletter'>
        <span onClick={() => setModalDisplay(false)}>
          <Close width='20' height='20' className='close' />
        </span>
        <div className={infoModalText?.includes('nieprawidłowy' || 'invalid' || 'pola') ? 'report-invalid' : 'report-correct'}>
          <p>{infoModalText}</p>
          {infoModalText?.includes('nieprawidłowy' || 'invalid' || 'pola') ? <ErrorIcon width='261' height='206' className='image-info-modal' /> : <MessageIcon width='361' height='286' className='image-info-modal' />}
        </div>
      </div>}

      <div className={`report-container__hero__form-flex ${mobileForm ? 'open' : ''}`}>
        <div className="form__titlebar" onClick={() => setMobileForm(!mobileForm)}>
          <div className="form__title">
            <img 
              className={`${arrow ? 'small' : 'big'}`}
              src="https://images.prismic.io/carcavelos/2f249112-881e-4d5f-a3e7-a2c42a5ee50e_report-details.png?auto=compress,format" 
              alt="colorful graphic"
            />
            <div>
              {locale?.formTitle}
              { !arrow && <div className="form__description">{locale?.formLabel}</div> }
            </div>
          </div>
          { arrow && <div className="close-btn"><Arrow /></div> }
        </div>

        <div className="form-wrapper">
          { arrow && <div className="form__description">{locale?.formLabel}</div> }
          <label>{RichText.asText(report?.form?.form_mail?.raw || [])}</label>
          <input
            type="email"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            required
            placeholder="jan@kowalski.com"
            onChange={onMailChange}
          />
          <label>{RichText.asText(report?.form?.form_industry?.raw || [])}</label>
          <Select
            defaultValue={language === 'pl' ? 'Wybierz' : 'Choose'}
            onChange={onIndustryChange}
            options={industryOptions}
            index={11}
          />
          <div className='report-container__hero__form-flex__checkbox-container'>
            <label className="report-container__hero__form-flex__checkbox-container">
              <p className='text'>{locale?.newsletterCheckbox}</p>
              <input type="checkbox" onChange={(e) => onNewsletterChange(e.target.checked)} checked={newsletterChecked} value='newsletter' />
              <span className="checkmark"></span>
            </label>
          </div>
          <p className='report-container__hero__form-flex__privacy'>
            {locale?.newsletterConsent} <Link to={`${language}/privacy-policy/`}>{locale?.consentLink}</Link>
          </p>
          <button onClick={onDownloadReport} className={report?.id} disabled={formDisabled} >
            {locale?.button}
          </button>
        </div>
      </div>
    </>
  );
};

export default ReportForm;

