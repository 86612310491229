import React from 'react'
import { useFooterData } from '../footerService'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { colors } from 'shared/constants'

const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 1440px;
  margin: auto;

  img {
    height: 80px;
  }
`

const LogotypesWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  background-color: ${colors.white};
  padding: 20px;
`

const Logotypes = () => {
  const data = useFooterData()

  return (
    <LogotypesWrapper>
      <StyledGatsbyImage
        image={data?.footer_logotypes?.gatsbyImageData}
        alt={data?.footer_logotypes?.alt ?? 'logotypes'}
      />
    </LogotypesWrapper>
  )
}

export default Logotypes
