import { graphql, useStaticQuery } from 'gatsby';
import {
  PrismicHomepage,
  PrismicHomepageDataType,
  PrismicHomepageEdge,
} from 'types/declarations';
import { useSelector } from 'react-redux';
import { RootState } from 'utils/store/store';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

export const useFooterData = (): PrismicHomepageDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicHomepage {
        totalCount
        edges {
          node {
            lang
            _previewable
            data {
              footer_blog {
                raw
              }
              footer_coming_soon {
                raw
              }
              footer_contact {
                raw
              }
              footer_contact_page {
                raw
              }
              footer_customer_stories {
                raw
              }
              footer_files {
                raw
              }
              footer_industry_reports {
                raw
              }
              footer_other {
                raw
              }
              footer_privacy_policy {
                raw
              }
              footer_recruitment {
                raw
              }
              footer_regulations {
                raw
              }
              footer_resources {
                raw
              }
              footer_terms_of_service {
                raw
              }
              footer_products {
                richText
              }
              footer_product_placeme {
                richText
              }
              footer_product_source {
                richText
              }
              footer_product_target {
                richText
              }
              footer_register {
                richText
              }
              footer_log_in {
                richText
              }
              footer_copyright {
                richText
              }
              footer_financed_projects {
                richText
              }
              footer_logotypes {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicHomepage.edges
    .map((edge: PrismicHomepageEdge) => edge.node)
    .filter((node: PrismicHomepage) => node.lang === language)
    .map((node: PrismicHomepage) => node.data)[0];
};
