import { graphql, useStaticQuery } from 'gatsby'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { PrismicFinancedProjects, PrismicFinancedProjectsDataType, PrismicFinancedProjectsEdge } from '../types/FinancedProjects'

export const useFinancedProjectsData = (): PrismicFinancedProjectsDataType => {
  const { language } = useSelector((state: RootState) => state.i18n)
  const result = useStaticQuery(graphql`
    {
      allPrismicFinancedProjects {
        edges {
          node {
            lang
            _previewable
            data {
              seo_title {
                richText
              }
              seo_description {
                richText
              }
              seo_canonical_url {
                url
              }
              header_title {
                richText
              }
              header_subtitle {
                richText
              }
              header_image {
                gatsbyImageData
                url
                alt
              }
              financed_page_content {
                richText
              }
              financed_hashtags {
                hashtag {
                  richText
                }
              }
            }
          }
        }
      }
    }
  `)
 
  const { data } = useMergePrismicPreviewData(result)

  return data.allPrismicFinancedProjects.edges
  .map((edge: PrismicFinancedProjectsEdge) => edge.node)
    .filter((node: PrismicFinancedProjects) => node.lang === language)
      .map((node: PrismicFinancedProjects) => node.data)[0]
}
