import { paginate } from "features/blog/pagination/paginationService";
import {
  Maybe,
  PrismicReportPage,
  PrismicReportPageDataType,
  PrismicReportPageEdge,
} from "types/declarations";
import {
  DownloadReport,
  GetReportDetails,
  GetReportsHome,
  GetReportsSearchResults,
  Report,
  SearchFilter,
  Tag,
} from "features/reports/reportsModel";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

const compareTagsFilter = (report: Report, filters: Array<SearchFilter>) => {
  const selectedFilters = filters?.filter(filter => filter?.selected);

  if (selectedFilters?.length === 0) {
    return true;
  }

  let isTagPresent = false;
  report?.tags?.forEach((postTag: Tag) => {
    selectedFilters?.forEach((filter: SearchFilter) => {
      if (filter?.value?.toLowerCase() === postTag?.value?.toLowerCase()) {
        isTagPresent = true;
      }
    });
  });

  return isTagPresent;
};

const getHomeReports = ({
  filters,
  pagination,
  allReports,
}: GetReportsHome): Array<Report> => {
  const results = paginate(
    allReports?.filter((report: Report) => compareTagsFilter(report, filters)),
    pagination
  );
  return results as Report[];
};

const getSearchResults = ({
  allReports,
  query,
  filters,
  pagination,
}: GetReportsSearchResults): Array<Report> => {
  const results = paginate(
    allReports
      ?.filter((post: Report) => compareTagsFilter(post, filters))
      .filter((post: Report) =>
        post?.title?.toLowerCase().includes(query.toLowerCase())
      ),
    pagination
  );
  return results as Report[];
};

const getReportDetails = ({
  allReports,
  link,
}: GetReportDetails): Maybe<Report> => {
  if (allReports) {
    const index = allReports.findIndex(
      (report: Report) => report?.link === link
    );
    if (index !== -1) {
      return allReports[index];
    } else {
      return null;
    }
  } else {
    return null;
  }
};
const downloadReport = (
  props: DownloadReport,
  lang: string,
  setInfoModalText: React.Dispatch<React.SetStateAction<string>>,
  setModalDisplay: React.Dispatch<React.SetStateAction<boolean>>,
  formId: string,
  openReportInNewTab: boolean,
  modalInfoText: string
): void => {
  const getUrlByLanguage = (lang: string) => {
    switch (lang) {
      case "pl":
        return `https://api.hsforms.com/submissions/v3/integration/submit/20363741/${formId}`;
      case "en-pl":
        return `https://api.hsforms.com/submissions/v3/integration/submit/20363741/${formId}`;
      default:
        return `https://api.hsforms.com/submissions/v3/integration/submit/20363741/${formId}`;
    }
  };
  const xhr = new XMLHttpRequest();
  const url = getUrlByLanguage(lang);

  // Example request JSON:
  const data = {
    'fields': [
      {
        'name': 'firstname',
        'value': props?.mail,
      },
      {
        'name': "email",
        'value': props?.mail,
      },
      {
        'name': "client_industry",
        'value': props?.industry,
      },
      {
        'name': 'zgoda___komunikacja_marketingowa',
        'value': props?.newsletter
      }
    ],
    'legalConsentOptions': {
      'consent': {
        'consentToProcess': true,
        'text':
          "I agree to allow Dataplace.ai to store and process my personal data.",
        'communications': [
          {
            'value': props?.newsletter,
            'subscriptionTypeId': 999,
            'text':
              "I agree to receive marketing communications from Dataplace.ai",
          },
        ],
      },
    },
  };

  const final_data = JSON.stringify(data);

  xhr.open("POST", url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status === 200) {
      openReportInNewTab
      ? (
        window.open(props.reportPdf, "_blank"),
        setInfoModalText(
          "Wiedza to klucz do sukcesu! Wybrany przez Ciebie raport wyświetli się w osobnej zakładce. Przeglądarki często automatycznie blokują wyskakujące okna. Żeby móc je otworzyć, należy kliknąć w pole adresu w przeglądarce lub zmienić ustawienia przeglądarki."
        )
      )
      : setInfoModalText(modalInfoText);
      setModalDisplay(true);
    } else if (
      xhr.readyState == 4 &&
      JSON.parse(xhr.responseText).errors[0].errorType === "REQUIRED_FIELD"
    ) {
      setModalDisplay(true);
      setInfoModalText("Musisz uzupełnić wszystkie pola z gwiazdką.");
    } else if (
      xhr.readyState == 4 &&
      JSON.parse(xhr.responseText).errors[0].errorType === "INVALID_EMAIL"
    ) {
      setModalDisplay(true);
      setInfoModalText("Podany e-mail jest nieprawidłowy.");
    }
  };

  // Sends the request

  xhr.send(final_data);
};

export const useReportPageData = (): PrismicReportPageDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  
  const resultS = useStaticQuery(graphql`
    {
      allPrismicReportPage {
        edges {
          node {
            lang
            _previewable
            data {
              seo_title {
                text
              }
              seo_description {
                text
              }
              seo_canonical_url {
                url
              }
              title {
                raw
              }
              image {
                alt
                url
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicReportPage.edges
    .map((edge: PrismicReportPageEdge) => edge.node)
    .filter((node: PrismicReportPage) => node.lang === language)
    .map((node: PrismicReportPage) => node.data)[0];
};

export default {
  getSearchResults,
  getHomeReports,
  getReportDetails,
  downloadReport,
};
